<template>
    <div class="base-input-container">
        <template v-if="option == 'textArea'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>
            <textarea
                :class="[isIdEmployee, 'base-area']"
                :readonly="idEmployee == true"
                @input="debounceInput"
                :value="value"
            />
        </template>
        <template v-else-if="option == 'password'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired === True" class="required-input">*</div>

            </div>
            <input
                type="password"
                :class="[isIdEmployee, 'base-input']"
                :readonly="idEmployee == true"
                @input="debounceInput"
                v-model="password"
                required
            />
        </template>

        <template v-else-if="option == 'dayPicker'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>
            <v-date-picker v-model="date" :locale="lang" :masks="masks">
                <template v-slot="{ inputValue, inputEvents }">
                    <input
                        class="base-input"
                        :value="inputValue"
                        v-on="inputEvents"
                    />
                </template>
            </v-date-picker>
        </template>

        <template v-else-if="option == 'formSelect'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>

            <select class="base-select-form" v-model="selected">
                <option disabled value="" class="base-select-option">
                    {{ title }}
                </option>
                <option v-for="item in listData" :key="item.id" :value="item.name">
                    {{ item.name }}
                </option>
            </select>
        </template>

        <template v-else-if="option=='multiSelect'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>
            <md-field>
                <md-select v-model="multipleSelections" multiple>
                    <md-option
                        v-for="group in listGroup"
                        v-bind:key="group.id"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </md-option>
                </md-select> 
            </md-field>
        </template>

        <template v-else-if="option == 'formGender'">
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>

            <select class="base-select-form" v-model="selected">
                <option disabled value="" class="base-select-option">
                     {{ title }}
                </option>
                <option v-for="item in listData" :key="item" :value="item">
                    <template v-if="item == 'Nu'">Nữ {{ item }}</template>
                    <template v-else>Nam {{ item }}</template>
                </option>
            </select>
        </template>

        <template v-else-if="option == 'formActive'">
            <div class="base-label">{{ title }}</div>

            <select class="base-select-form" v-model="selectedActive">
                <option disabled value="" class="base-select-option">
                    {{ title }}
                </option>
                <option v-for="item in listData" :key="item" :value="item">
                    {{ item == true ? $t('Active') :  $t('Unactive') }}
                </option>
            </select>
        </template>

        <template v-else-if="option == 'checkbox'">
            <div class="wrapCheckboxZALO">
                <input type="checkbox" id="zalo" name="zalo" @click="checkZalo" :checked="false">
                <label class="base-label ml-16">{{ $t("Employee.ReceiveZaloNoti") }}</label>
            </div>
        </template>

        <template v-else>
            <div class="base-label">
                {{ title }}
                <div v-if="isRequired == true" class="required-input">*</div>
                <div v-else></div>
            </div>
            <input
                :class="[isIdEmployee, 'base-input']"
                :readonly="idEmployee == true"
                @input="debounceInput"
                :value="value"
            />
        </template>

        
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import _ from "lodash";

import { Api } from "@/api/index";
const getListGroup = Api.get("getListGroupV2");
const getSubUserInfo = Api.get("getSubUserInfo");

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

export default {
    beforeMount() {
        if (this.option == "multiSelect" ) {
            getListGroup.get().then((response) => {
                this.listGroup = response.data.listResponse;
            });
            this.iduser = this.$route.params.data;
            getSubUserInfo.get(this.iduser).then((response) => {
                for (var i = 0; i < response.data.data.listGroup.length; i++) {
                    this.multipleSelections.push(response.data.data.listGroup[i].id)
                }
            })
        }
    },
    mounted() {
        this.lang = localStorage.lang == "vn" ? "vi" : "en"
    },
    props: {
        isUseZalo: {
            value: Boolean,
            default: false,
        },
        title: {
            type: String,
        },
        value: {
            value: String,
        },
        option: {
            value: String,
        },
        idEmployee: {
            value: String,
            default: false,
        },
        birthDay: {
            value: Date,
        },
        listData: {
            value: Array,
            default: ["", ""]
        },
        responseGroup: {
            value: String,
        },
        isRequired: {
            value: Boolean,
            default: false,
        },
    },

    data() {
        return {
            lang: "vi",
            masks: {
                input: "DD/MM/YYYY",
            },
            listGroup: [],
            date: new Date(),
            selected: "",
            selectedActive: "",
            multipleSelections: [],
            multiple: 'true',
            password: ""
        };
    },
    computed: {
        isIdEmployee() {
            return this.idEmployee == true ? "enable-cursor" : "disable-cursor";
        },
    },
    methods: {
        checkZalo() {
            var checkBox = document.getElementById("zalo")
            this.$emit("changeZaloOption", checkBox.checked)
        },
        displayDate() {
            var formatDate = moment(val, "DD/MM/YYYY").format("X");
            this.birthdayFormat = moment(formatDate).format("DD/MM/YYYY");
            return this.birthdayFormat;
        },
        debounceInput: _.debounce(function (e) {
            this.value = e.target.value;
        }, 200),
    },
    watch: {
        isUseZalo(val) {
            if (val == "true") {
                document.getElementById("zalo").checked = true
            } else {
                document.getElementById("zalo").checked = false
            }
        },
        multipleSelections(val) {
            this.$emit("selectedGroup", val)
        },
        birthDay(val) {
            var formatDate = moment(val, "DD/MM/YYYY").toDate();
            this.date = formatDate;
        },
        responseGroup(val) {
            this.selected = val;
            this.selectedActive = val;
        },
        password(val) {
            this.$emit("passwordChanged", val)
        },
        value(val) {
            
            this.$emit("name", val);
            this.$emit("employeeID", val);
            this.$emit("email", val);
            this.$emit("identificationCard", val);
            this.$emit("position", val);
            this.$emit("phoneNumber", val);
            this.$emit("address", val);
            this.$emit("taxID", val);
        },
        selected(val) {
            this.$emit("selectedDepartment", val);
            this.$emit("selectedGroup", val);
        },
        selectedActive(val) {
            this.$emit("selectedStatus", val);
        },
        date(val) {
            this.$emit("date", val);
        },
    },
};
</script>

<style lang="scss">
.base-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 0 0 40%;
    margin-bottom: 20px;

    .base-label {
        font-size: 16px;
        color: #000;
        display: flex;
        gap: 5px;

        .required-input {
            font-size: 20px;
            color: red;
        }
    }

    .base-select-form {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid #eaeaea;
        font-weight: bold;
        font-size: 16px;
        color: #000;
    }

    .base-area {
        height: 100%;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid #eaeaea;
        font-weight: bold;
    }

    .base-input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid #eaeaea;
        font-weight: bold;
    }

    .enable-cursor {
        cursor: not-allowed;
    }
    .wrapCheckboxZALO {
        display: flex;
        align-items: center;
    }
}
</style>
