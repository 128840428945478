<template>
    <div class="add-employee-container">
        <div class="pick-avatar-employee">
            <div class="avatar-employee-container">
                <img :src="avatarEmp" />
            </div>

            <div
                class="button"
                @click="$refs.file.click()"
            >
                <input
                    type="file"
                    ref="file"
                    @change="uploadImage($event)"
                    accept="image/*"
                />
                {{ $t("Employee.AddPicture") }}
            </div>
        </div>
        <div class="form-add-employee">
            <div class="info-employee">
                <BaseTextInput
                    :title="$t('Employee.Name')"
                    :value="dataInfoEmployee.name"
                    @name="chanegNameInput"
                    :isRequired="isRequired"
                />
                <BaseTextInput
                    :title="$t('Employee.Location')"
                    option="formSelect"
                    :responseGroup="responseGroup"
                    :listData="listGroup"
                    @selectedGroup="selectedGroup"
                    :isRequired="isRequired"
                />
                <BaseTextInput
                    :title="$t('Employee.BirthDate')"
                    option="dayPicker"
                    :birthDay="dataInfoEmployee.birthDate"
                    @date="chanegDateInput"
                />
                
                <BaseTextInput
                    :title="$t('Employee.Department')"
                    option="formSelect"
                    :listData="listDepartment"
                    :responseGroup="responseDepartment"
                    @selectedDepartment="selectedDepartment"
                    :isRequired="isRequired"
                />
                <BaseTextInput
                    :title="$t('Employee.CitizenID')"
                    :value="dataInfoEmployee.identificationCard"
                    @identificationCard="chanegIdentificationCardInput"
                />
                <BaseTextInput
                    :title="$t('Employee.Position')"
                    :value="dataInfoEmployee.position"
                    @position="chanegPositionInput"
                    :isRequired="isRequired"
                />
                <BaseTextInput
                    :title="$t('Customers.Phone')"
                    :value="dataInfoEmployee.phoneNumber"
                    @phoneNumber="chanegPhoneInput"
                />
                <BaseTextInput
                    :title="$t('Employee.EmployeeID')"
                    :value="dataInfoEmployee.employeeID"
                    @employeeID="chanegEmployeeIDInput"
                />
                
                <BaseTextInput
                    :title="$t('Employee.Email')"
                    :value="dataInfoEmployee.email"
                    @email="chanegEmailInput"
                />
                <BaseTextInput
                    :title="$t('Employee.TaxID')"
                    :value="dataInfoEmployee.taxID"
                    @taxID="chanegTaxInput"
                />
                
                <BaseTextInput
                    :title="$t('Employee.Address')"
                    option="textArea"
                    :value="dataInfoEmployee.address"
                    @address="chanegAddressInput"
                />
                <div class="form-container-right">
                    <BaseTextInput
                        :title="$t('Employee.StartDate')"
                        option="dayPicker"
                        :birthDay="dataInfoEmployee.dayComeIn"
                        @date="chanegDayInput"
                    />
                    
                </div>
                <BaseTextInput
                    :title="$t('Employee.Address')"
                    option="checkbox"
                    :isUseZalo="isUseZalo"
                    @changeZaloOption="changeZaloInput"
                />
                
            </div>
            <div class="action-add-employee">
                <div
                    class="btn-employee btn-add-employee"
                    v-if="dataInfoEmployee.id == undefined"
                    @click="addNewEmployee"
                >
                    {{ $t("Employee.Submit") }}
                </div>
                <div
                    class="btn-employee btn-add-employee"
                    v-else
                    @click="editNewEmployee()"
                >
                    {{ $t("Customers.EditorSubmit") }}
                </div>
                <div
                    class="btn-employee btn-cancel-employee"
                    @click="redirectEmployeeManage"
                >
                    {{ $t("Customers.Cancel") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {BaseTextInput} from "@/components";
import {Api} from "@/api/index";
import moment from "moment";

const getListGroup = Api.get("getListGroupV2");
const getDetailEmployeeV2 = Api.get("getDetailEmployeeV3");
const addEmployeeV2 = Api.get("addEmployeeV3");
const editEmployeeV2 = Api.get("editEmployeeV3");
const getListDepartment = Api.get("getListDepartment");
const getListDepartmentByGroup = Api.get("getListDepartmentByGroup")
const defaultImageSource = "/img/user.png";

export default {
    components: {
        BaseTextInput
    },
    props: {
        data: {
            type: Array
        }
    },
    data() {
        return {
            isUseZalo: false,
            noticeZalo: "false",
            listGroup: [],
            listDepartment: [],
            listActive: [true, false],
            responseGroup: "",
            responseDepartment: "",
            responseBack: false,
            isRequired: true,
            sendingImage: "",
            isSelectedGroup: false,
            dataInfoEmployee: {
                name: "",
                employeeID: "",
                email: "",
                identificationCard: "",
                position: "",
                phoneNumber: "",
                listGroup: [],
                birthDate: "",
                active: Boolean(),
                department: "",
                address: "",
                image: "",
                taxID: "",
                dayComeIn: moment().format("DD/MM/YYYY"),
            },
            idEmployee: "",
            AVATAR_PICKER_ID: "avatar-file-picker",
            avatarEmp: defaultImageSource
        };
    },
    mounted() {
        getListGroup.get().then(response => {
            var listGroup = response.data.listResponse;
            this.listGroup = listGroup;
        });

        if (this.$route.params.data != undefined) {
            this.idEmployee = this.$route.params.data;
            var self = this;
            getDetailEmployeeV2.get(this.idEmployee).then(response => {
                Object.keys(response.data.findEmployeeDetail).map(function(
                    key,
                    property
                ) {
                    self.dataInfoEmployee[key] =
                        response.data.findEmployeeDetail[key];
                });
                // self.dataInfoEmployee =
                this.avatarEmp = response.data.findEmployeeDetail.image;

                var groupID = this.dataInfoEmployee.listGroup;
                var departmentID = this.dataInfoEmployee.department;
                var statusID = this.dataInfoEmployee.active;
                this.isUseZalo = response.data.findEmployeeDetail.noticeZalo
                if (groupID != null) {
                    this.responseGroup = groupID[0];
                } else {
                    this.responseGroup = "";
                }

                if (departmentID) {
                    this.responseDepartment = departmentID;
                } else {
                    this.responseDepartment = "";
                }

            });
        }
    },
    beforeDestroy() {
        this.$swal.close();
    },
    methods: {
        validateEmail(email) {
            if (email == "") {
                return true;
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
        },
        uploadImage(event) {
            // Reference to the DOM input element
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {
                // create a new FileReader to read this image and convert to base64 format
                this.sendingImage = input.files[0];
                var reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = e => {
                    // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                    // Read image as base64 and set to imageData
                    if (
                        ["image/jpg", "image/jpeg", "image/png"].includes(
                            input.files[0].type
                        )
                    ) {
                        this.avatarEmp = e.target.result;
                    } else {
                        this.$swal("Ảnh sai định dạng", "", "warning");
                    }
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsDataURL(input.files[0]);
            }
        },
        redirectEmployeeManage() {
            this.$router.push({
                name: "Quản lý nhân viên"
            });
        },
        selectedGroup(val) {
            if (this.dataInfoEmployee.listGroup == null) {
                this.dataInfoEmployee.listGroup = [];
            }
            this.dataInfoEmployee.listGroup = [];
            this.dataInfoEmployee.listGroup.push(val);
            if (this.dataInfoEmployee.listGroup.length == 0) {
                this.isSelectedGroup = false;
            } else {
                var self = this;
                getListDepartmentByGroup
                    .get(val)
                    .then(response => {
                        self.listDepartment = response.data.listResponse;
                        self.isSelectedGroup = true;
                    })
                    .catch(error => {
                        self.listDepartment = [];
                        self.isSelectedGroup = true;
                        this.$notify({
                            timeout: 2500,
                            message: self.$t("EmployeeResponse."+ error.response.data.message.replace(".","")),
                            icon: "add_alert",
                            horizontalAlign: "right",
                            verticalAlign: "top",
                            type: "primary"
                        });
                    });
            }
        },
        selectedDepartment(val) {
            this.dataInfoEmployee.department = val;
        },
        changeZaloInput(val) {
            this.dataInfoEmployee.noticeZalo = val.toString() 
        },
        chanegNameInput(val) {
            this.dataInfoEmployee.name = val;
        },
        chanegEmployeeIDInput(val) {
            this.dataInfoEmployee.employeeID = val;
        },
        chanegEmailInput(val) {
            this.dataInfoEmployee.email = val;
        },
        chanegIdentificationCardInput(val) {
            this.dataInfoEmployee.identificationCard = val;
        },
        chanegPositionInput(val) {
            this.dataInfoEmployee.position = val;
        },
        chanegPhoneInput(val) {
            this.dataInfoEmployee.phoneNumber = val;
        },
        chanegAddressInput(val) {
            this.dataInfoEmployee.address = val;
        },
        chanegDateInput(val) {
            this.dataInfoEmployee.birthDate = moment(val).format("DD/MM/YYYY");
        },
        chanegDayInput(val) {
            this.dataInfoEmployee.dayComeIn = moment(val).format("DD/MM/YYYY");
        },
        chanegTaxInput(val) {
            this.dataInfoEmployee.taxID = val;
        },

        addNewEmployee() {
            this.dataInfoEmployee.phoneNumber = this.dataInfoEmployee.phoneNumber.replaceAll(
                /\s/g,
                ""
            );
            if (this.dataInfoEmployee.name == "") {
                this.$swal(this.$t("CustomerResponse.CM00002"), "", "error");
            } else if (this.dataInfoEmployee.listGroup == "") {
                this.$swal(this.$t("EmployeeResponse.EM00003"), "", "error");
            } else if (this.dataInfoEmployee.department == "") {
                this.$swal(this.$t("EmployeeResponse.EM00005"), "", "error");
            } else if (this.dataInfoEmployee.position == "") {
                this.$swal(this.$t("EmployeeResponse.EM00004"), "", "error");
            } else if (
                this.validateEmail(this.dataInfoEmployee.email) == false
            ) {
                this.$swal(this.$t("UserResponse.UM00002"), "", "error");
            } else if (this.avatarEmp == defaultImageSource) {
                this.$swal(this.$t("CustomerResponse.CM00001"), "", "error");
            } else {
                this.$swal({
                    title: this.$t("CustomerResponse.CM99999"),
                    allowOutsideClick: false
                });
                this.$swal.showLoading();
                this.Sending("add")
                    .then(response => {
                        this.$swal.close();
                        this.$swal(
                            this.$t(
                                "EmployeeResponse." +
                                    response.data.message.replace(".", "")
                            ),
                            "",
                            "success"
                        ).then(result => {
                            this.$router.push({
                                name: "Quản lý nhân viên"
                            })
                        })
                    })
                    .catch(error => {
                        if (error.response === undefined) {
                            this.$swal.close();
                            this.$swal(
                                this.$t("CustomerResponse.CM99998"),
                                "",
                                "warning"
                            );
                        } else {
                            this.$swal.close();
                            this.$swal(
                                this.$t(
                                    "EmployeeResponse." +
                                    error.response.data.message.replace(".", "")
                                ),
                                "",
                                "warning"
                            );
                        }
                    });
            }
        },
        editNewEmployee() {
            var self = this
            if (this.dataInfoEmployee.phoneNumber != null) {
                this.dataInfoEmployee.phoneNumber = this.dataInfoEmployee.phoneNumber.replaceAll(
                    /\s/g,
                    ""
                );
            } else {
                this.dataInfoEmployee.phoneNumber = ""
            }
            if (this.dataInfoEmployee.name == "") {
                this.$swal(this.$t("CustomerResponse.CM00002"), "", "error");
            } else if (this.dataInfoEmployee.listGroup == "") {
                this.$swal(this.$t("EmployeeResponse.EM00003"), "", "error");
            } else if (this.dataInfoEmployee.department == "") {
                this.$swal(this.$t("EmployeeResponse.EM00005"), "", "error");
            } else if (this.dataInfoEmployee.position == "") {
                this.$swal(this.$t("EmployeeResponse.EM00004"), "", "error");
            } else if (
                this.validateEmail(this.dataInfoEmployee.email) == false
            ) {
                this.$swal(this.$t("UserResponse.UM00002"), "", "error");
            } else if (this.avatarEmp == defaultImageSource) {
                this.$swal(this.$t("CustomerResponse.CM00001"), "", "error");
            } else {
                const temp = this.avatarEmp.split(",")[1];
                if (temp == undefined) {
                    this.dataInfoEmployee.image = this.avatarEmp.split(",")[0];
                } else {
                    this.dataInfoEmployee.image = temp;
                }
                this.$swal({
                    title: this.$t("CustomerResponse.CM99999"),
                    allowOutsideClick: false
                });
                this.$swal.showLoading();
                this.Sending("update")
                    .then(response => {
                        this.$swal.close();
                        this.$swal(
                            this.$t(
                                "EmployeeResponse.EM00031"
                            ),
                            "",
                            "success"
                        ).then(result => {
                            this.$router.push({
                                name: "Quản lý nhân viên"
                            })
                        })
                    })
                    .catch(error => {
                        this.$swal.close();
                        this.$swal(
                            this.$t(
                                "EmployeeResponse." +
                                    error.response.data.message.replace(".", "")
                            ),
                            "",
                            "warning"
                        );
                    });
            }
        },
        Sending(api) {
            this.dataInfoEmployee.image = this.avatarEmp.split(",")[1];
            var listGroup = [];
            listGroup.push(this.dataInfoEmployee.listGroup);
            var bodyFormData = new FormData();
            bodyFormData.append("name", this.dataInfoEmployee.name);
            bodyFormData.append("employeeID", this.dataInfoEmployee.employeeID);
            bodyFormData.append("email", this.dataInfoEmployee.email);
            bodyFormData.append(
                "identificationCard",
                this.dataInfoEmployee.identificationCard
            );
            bodyFormData.append("position", this.dataInfoEmployee.position);
            bodyFormData.append(
                "phoneNumber",
                this.dataInfoEmployee.phoneNumber
            );
            bodyFormData.append("listGroup", '["' + listGroup + '"]');
            bodyFormData.append("birthDate", this.dataInfoEmployee.birthDate);
            bodyFormData.append("department", this.dataInfoEmployee.department);
            bodyFormData.append("address", this.dataInfoEmployee.address);
            bodyFormData.append("image", this.sendingImage);
            bodyFormData.append("taxID", this.dataInfoEmployee.taxID);
            bodyFormData.append("dayComeIn", this.dataInfoEmployee.dayComeIn);
            bodyFormData.append("noticeZalo", this.dataInfoEmployee.noticeZalo);
            if (api === "add") {
                let json = addEmployeeV2.post(bodyFormData);
                return json;
            } else if (api == "update") {
                let json = editEmployeeV2.put(this.dataInfoEmployee.id, bodyFormData);
                return json;
            }
            
            
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.add-employee-container {
    width: 50%;
    margin: 0 auto;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

    .pick-avatar-employee {
        .avatar-employee-container {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .button {
            color: white;
            font-size: 16px;
            background: #3fb37f;
            cursor: pointer;
            transition: background 0.5s;
            font-family: Open Sans, Arial;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            border-radius: 10px;

            &:hover {
                background: #38d890;
            }
            input {
                width: 100%;
                height: 100%;
                display: none;
            }
        }
    }

    .form-add-employee {
        height: auto;
        padding: 40px 0 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .info-employee {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-container-right {
                width: 40%;
            }
        }

        .action-add-employee {
            display: flex;
            justify-content: center;
            gap: 40px;

            .btn-employee {
                width: auto;
                height: auto;
                border: 1px solid transparent;
                margin-top: 20px;
                padding: 10px;
                border-radius: 10px;
                font-size: 14px;
                cursor: pointer;
            }

            .btn-add-employee {
                background-color: #3fb37f;
                color: white;

                &:hover {
                    background: #38d890;
                }
            }

            .btn-cancel-employee {
                border-color: #707070;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
