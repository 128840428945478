<template>
  <div class="input-container">
    <div class="label">{{ title }}</div>
    <input class="input" :value="value" readonly/>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      value: String,
    },
  },
};
</script>

<style lang="scss">
.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0 0 50%;
  padding: 0 25px 20px;

  .label{
    font-size: 16px;
    color: #000;
  }

  .input{
    width: 75%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #EAEAEA;
    cursor:not-allowed;
    font-weight: bold;
  }
}
</style>
