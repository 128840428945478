<template>
    <div class="add-user-container">
        <div class="form-add-user">
            <div class="info-user">
                <BaseTextInput
                    :title="$t('Settings.Name')"
                    :value="dataInfoUser.name"
                    @name="changeNameInput"
                />
                <BaseTextInput
                    title="Email"
                    :value="dataInfoUser.email"
                    @email="changeEmailInput"
                />
                <BaseTextInput
                    :title="$t('Settings.Password')"
                    option="password"
                    :value="dataInfoUser.password"
                    :isRequired="!(idUser !== undefined)"
                    @passwordChanged="passwordChanged"
                />
                <BaseTextInput
                    :title="$t('Settings.Status')"
                    option="formActive"
                    :listData="listActive"
                    :responseGroup="responseActive"
                    @selectedStatus="selectedStatus"
                />
                <BaseTextInput
                    :title="$t('Settings.Store')"
                    option="multiSelect"
                    :responseGroup="responseGroup"
                    :listData="listGroup"
                    @selectedGroup="selectedGroup"
                    :isRequired="isRequired"
                />
                <BaseTextInput
                    :title="$t('Settings.Contact')"
                    :value="dataInfoUser.phoneNumber"
                    @phoneNumber="changePhoneInput"
                />
            </div>
            <div class="action-add-user">
                <div
                    class="btn-user btn-add-user"
                    v-if="dataInfoUser.id == undefined"
                    @click="addNewUser"
                >
                    {{ $t("Settings.AddUser") }}
                </div>
                <div
                    class="btn-user btn-add-user"
                    v-else
                    @click="editNewUser()"
                >
                    {{ $t("Customers.EditorSubmit") }}
                </div>
                <div
                    class="btn-user btn-cancel-user"
                    @click="redirectUserManage"
                >
                    {{ $t("Customers.Cancel") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseTextInput } from "@/components";
import { Api } from "@/api/index";
const getListGroup = Api.get("getListGroupV2");
const adduserV2 = Api.get("adduserV3");
const getSubUserInfo = Api.get("getSubUserInfo");
const editSubUser = Api.get("editSubUser");

const defaultImageSource = "/img/user.png";

export default {
    components: {
        BaseTextInput
    },
    props: {
        data: {
            type: Array
        }
    },
    data() {
        return {
            listGroup: [],
            listDepartment: [],
            listActive: [true, false],
            responseGroup: "",
            responseDepartment: "",
            responseActive: false,
            responseBack: false,
            isRequired: true,
            dataInfoUser: {
                name: "",
                userID: "",
                email: "",
                listGroup: [],
                password: "",
                phoneNumber: "",
                status: false
            },
            iduser: "",
            AVATAR_PICKER_ID: "avatar-file-picker",
            avatarEmp: defaultImageSource
        };
    },
    beforeCreate() {
        if (this.$route.params.data != undefined) {
            this.iduser = this.$route.params.data;
            getSubUserInfo.get(this.iduser).then(response => {
                self = this;
                Object.keys(response.data.data).map(function(key, property) {
                    self.dataInfoUser[key] = response.data.data[key];
                });
            });
        }
    },
    beforeDestroy() {
        this.$swal.close();
    },
    methods: {
        validateEmail(email) {
            if (email == "") {
                return true;
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
        },
        passwordChanged(val) {
            this.dataInfoUser.password = val;
        },
        redirectUserManage() {
            this.$router.push({
                name: "Quản lý người dùng"
            });
        },
        selectedGroup(val) {
            if (this.dataInfoUser.listGroup == null) {
                this.dataInfoUser.listGroup = [];
            } else {
                if (val.length != 0) {
                    if (typeof val[0] == "object") {
                        for (group in val) {
                            this.dataInfoUser.push(group.id);
                        }
                    } else {
                        this.dataInfoUser.listGroup = val;
                    }
                } else {
                    this.dataInfoUser.listGroup = val;
                }
            }
        },
        changeNameInput(val) {
            this.dataInfoUser.name = val;
        },
        changeEmailInput(val) {
            this.dataInfoUser.email = val;
        },
        changePositionInput(val) {
            this.dataInfoUser.position = val;
        },
        changePhoneInput(val) {
            this.dataInfoUser.phoneNumber = val;
        },
        selectedStatus(val) {
            this.dataInfoUser.status = val;
        },
        editNewUser() {
            this.dataInfoUser.userID = this.dataInfoUser.id;
            this.dataInfoUser.phoneNumber = this.dataInfoUser.phoneNumber.replaceAll(
                /\s/g,
                ""
            );
            if (this.dataInfoUser.name == "") {
                this.$swal(this.$t("CustomerResponse.CM00002"), "", "error");
            } else if (this.dataInfoUser.listGroup.length == 0) {
                this.$swal(this.$t("EmployeeResponse.EM00003"), "", "error");
            } else if (this.validateEmail(this.dataInfoUser.email) == false) {
                this.$swal(this.$t("UserResponse.UM00002"), "", "error");
            } else {
                editSubUser
                    .put(this.dataInfoUser)
                    .then(response => {
                        this.$swal(
                            this.$t(
                                "UserResponse." +
                                    response.data.message.replace(".", "")
                            ),
                            "",
                            "success"
                        )
                    })
                    .catch(error => {
                        this.$swal(
                            this.$t(
                                "UserResponse." +
                                    error.response.data.message.replace(".", "")
                            ),
                            "",
                            "warning"
                        );
                    });
            }
        },
        Sending() {
            this.dataInfoUser.image = this.avatarEmp.split(",")[1];
            let json = adduserV2.post(this.dataInfoUser);
            return json;
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.add-user-container {
    width: 50%;
    margin: 0 auto;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

    .pick-avatar-user {
        .avatar-user-container {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .button {
            color: white;
            font-size: 16px;
            background: #3fb37f;
            cursor: pointer;
            transition: background 0.5s;
            font-family: Open Sans, Arial;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            border-radius: 10px;

            &:hover {
                background: #38d890;
            }
            input {
                width: 100%;
                height: 100%;
                display: none;
            }
        }
    }

    .form-add-user {
        height: auto;
        padding: 40px 0 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .info-user {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-container-right {
                width: 40%;
            }
        }

        .action-add-user {
            display: flex;
            justify-content: center;
            gap: 40px;

            .btn-user {
                width: auto;
                height: auto;
                border: 1px solid transparent;
                margin-top: 20px;
                padding: 10px;
                border-radius: 10px;
                font-size: 14px;
                cursor: pointer;
            }

            .btn-add-user {
                background-color: #3fb37f;
                color: white;

                &:hover {
                    background: #38d890;
                }
            }

            .btn-cancel-user {
                border-color: #707070;
            }
        }
    }
}
</style>
